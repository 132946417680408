body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.manyosSpin{
  top:0px!important;
  left:0px!important;
  position:relative!important;
  font-size: 200px!important;

}


.table-block{
  border-bottom:1px solid #80808045;
  padding:10px;
}

.table-block-label{
  margin-top: -2px!important;
  color: #80808045!important;
  display:block;

}
.table-field-holder{
  float:left;
}
.ant-col-12{
  width: 50%!important;
}
.ant-col-24{
  width: 100%!important;
}
.ant-col-6{
  width: 25%!important;
}
.ant-col-18{
  width: 75%!important;
}

.table-row{
  margin: 0px 10px 0px 10px!important
}
